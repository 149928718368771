import React from "react";
import SingleProductImage from "./SingleProductImage";

function SingleProductGallery({ product, activeVariantVersion }) {
  console.log(
    "active Variation",
    activeVariantVersion,
    product.is_variable_product &&
      activeVariantVersion &&
      activeVariantVersion.media.length > 0
  );

  return (
    <>
      {product.is_variable_product &&
      activeVariantVersion &&
      activeVariantVersion.media.length > 0 ? (
        <>
          {activeVariantVersion.media.map((item) => {
            console.log("ITEM", item);
            return <SingleProductImage image={item} />;
          })}
        </>
      ) : (
        <>
          {product.media &&
            product.media.map((item) => {
              return <SingleProductImage image={item} />;
            })}
        </>
      )}
    </>
  );
}

export default SingleProductGallery;
