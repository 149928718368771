import React from 'react';
import Breadcrum from '../../components/breadcrum/Breadcrum';
import Footer from '../../components/common/footer/Footer';
import Header from '../../components/common/header/Header';
import {
  useGetWishlist,
  useAddToWishlist,
} from '../../shared/hooks/UseWishlist';
import { Link } from 'react-router-dom';
import { URI } from '../../domain/constant';
import {
  useAddToCart,
  useCheckInCart,
  useDecreaseQuantity,
  useRemoveFromCart,
} from '../../shared/hooks/UseCart';

function Wishlist({ product, activeVariantVersion }) {
  const [wishlist] = useGetWishlist();
  const [wishlistData, addData] = useAddToWishlist();

  const [productInCart] = useCheckInCart(product, activeVariantVersion);
  // const [cart, addData] = useAddToCart();
  const [removeFromCart] = useRemoveFromCart();

  const addToCart = async (product) => {
    if (!product.is_variable_product) {
      await addData({
        name: product.name,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        slug: product.slug,
        image: product.media && product.media[0],
        product: product._id,
      });
    } else {
      let title = '';
      activeVariantVersion &&
        activeVariantVersion.options &&
        activeVariantVersion.options.map((item) => {
          title = title + ' ' + item.value;
        });
      await addData({
        name: `${product.name} ${title}`,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        slug: `${product.slug}?variation=${activeVariantVersion._id}`,
        image: product.media && product.media[0],
        product: product._id,
      });
    }
  };
  return (
    <div>
      <Header />
      <div className='page-content'>
        <Breadcrum title={'Wishlist'} />
        {wishlist && wishlist.length > 0 ? (
          <section className='section-padding'>
            <div className='container'>
              <div className='d-flex align-items-center px-3 py-2 border mb-4'>
                <div className='text-start'>
                  <h4 className='mb-0 h4 fw-bold'>
                    Wishlist ({wishlist ? wishlist.length : 0} Items)
                  </h4>
                </div>
                <div className='ms-auto'>
                  <Link to='/' className='btn btn-light btn-ecomm'>
                    Continue Shopping
                  </Link>
                </div>
              </div>
              <div className='similar-products'>
                <div className='row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-4'>
                  {wishlist &&
                    wishlist.map((item) => {
                      return (
                        <div className='col'>
                          <div className='card rounded-0'>
                            <div
                              onClick={() => addData(item)}
                              className='btn-close wishlist-close position-absolute end-0 top-0'
                            />

                            <Link to={`/product/${item.slug}`}>
                              {item.media && (
                                <img
                                  src={`${URI}${item.media[0]}`}
                                  className='card-img-top rounded-0 product-default-img img-fluid'
                                />
                              )}
                            </Link>

                            <div className='card-body border-top text-center'>
                              <p className='mb-0 product-short-name product-name'>
                                <Link
                                  className='color-black'
                                  to={`/product/${item.slug}`}
                                >
                                  {item.name}
                                </Link>
                              </p>
                              <div className='product-price d-flex align-items-center gap-2 mt-2 justify-content-center'>
                                <div className='h6 fw-bold'>
                                  {' '}
                                  ₹{item.sale_price}
                                </div>
                                <div className='h6 fw-light text-muted text-decoration-line-through'>
                                  ₹{item.regular_price}
                                </div>
                                <div className='h6 fw-bold text-danger'>
                                  {parseInt(
                                    ((item.regular_price - item.sale_price) /
                                      item.regular_price) *
                                      100
                                  )}
                                  % OFF
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {/*end row*/}
              </div>
            </div>
          </section>
        ) : (
          <>
            <section className='section-padding'>
              <div className='container'>
                <img
                  src='/assets/images/wishlist-icon/wishlist-empty.jpg'
                  alt=''
                  className='wishlist-empty-img'
                />
                <div className='wishlist-empty-text'>
                  <h3>Hey, It feels so light!</h3>
                  <p>There is nothing in your wishlist. Lets add some items.</p>
                </div>

                <ul className='offcanvas-wishlist-action-button empty-cart-btn'>
                  <li className='offcanvas-wishlist-action-button-list'>
                    <Link
                      to={`/shop`}
                      className='offcanvas-wishlist-action-button-link'
                    >
                      View Shop
                    </Link>
                  </li>
                </ul>
              </div>
            </section>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Wishlist;
