import React from "react";
import Slider from "react-slick";
import { URI } from "../../domain/constant";
import Image from "../common/Image";
import NextArrowComponent from "../slider/NextArrowComponent";
import PreArrowComponent from "../slider/PreArrowComponent";

function ProductGalleryCard({ product, activeVariantVersion }) {
  var product_setting = {
    dots: true,
    infinite: true,
    nav: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrowComponent />,
    prevArrow: <PreArrowComponent />,
  };
  if (product && product.is_variable_product && activeVariantVersion) {
    return (
      <div>
        {activeVariantVersion &&
        activeVariantVersion.media &&
        activeVariantVersion.media.length > 0 ? (
          <Slider {...product_setting}>
            {activeVariantVersion.media.map((img) => {
              return (
                <div>
                  <img
                    src={`${URI}${img}`}
                    className="product-default-img img-fluid"
                  />
                </div>
              );
            })}
          </Slider>
        ) : (
          <>
            <Slider {...product_setting}>
              {product &&
                product.media.map((img) => {
                  return (
                    <div>
                      <img
                        src={`${URI}${img}`}
                        className="product-default-img img-fluid"
                      />
                    </div>
                  );
                })}
            </Slider>
          </>
        )}
      </div>
    );
  }
  return (
    <div>
      <Slider {...product_setting}>
        {product.media &&
          product.media.map((img) => {
            return (
              <div>
                {/* <img
                  src={`${URI}${img}`}
                  className="product-default-img img-fluid"
                /> */}
                <Image
                  src={`${URI}${img}`}
                  className="product-default-img img-fluid"
                />
              </div>
            );
          })}
      </Slider>
    </div>
  );
}

export default ProductGalleryCard;
