import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { URI } from "../../domain/constant";

function OrderCard({ order }) {
  return (
    <div>
      {order && (
        <div className="card rounded-0 mb-3">
          <div className="card-header">
            <div className="d-flex justify-content-between">
              <div>
                <h3>Order ID: {order.order_id}</h3>
              </div>
              <div className="shipped">
                <a href="#">
                  <i className="fa fa-truck"></i> {order.status}
                </a>
              </div>
            </div>
            <div className="order-delivery-flex">
              <div className="">
                <span>
                  Order date :{" "}
                  <b>
                    {" "}
                    {order.order_date &&
                      moment(order.order_date).format("DD-MMM-YYYY")}{" "}
                  </b>
                </span>
              </div>
              <div className="">
                {/* <p>
              <i className="fa fa-plane"> </i>
              Estimated Delivery: 14 May, 2022
            </p> */}
              </div>
            </div>
          </div>
          <div className="card-body">
            {order.products &&
              order.products.map((product) => {
                return (
                  <>
                    <div className="d-flex flex-column flex-xl-row gap-3">
                      <div className="product-img">
                        <img src={`${URI}${product.image}`} width={120} />
                      </div>
                      <div className="product-info flex-grow-1">
                        <Link to={`/products/${product.slug}`}>
                          <h5 className="fw-bold mb-1">{product.name}</h5>
                        </Link>
                      </div>
                      <div className="order-name-qu">
                        <h5>₹{product.sale_price}</h5>
                        <p>Qty. {product.quantity}</p>
                      </div>
                    </div>
                    <hr></hr>{" "}
                  </>
                );
              })}
            <div className="d-flex justify-content-between">
              {order.address && (
                <div className="address-box">
                  <h4>Delivery Address </h4>
                  <hr></hr>
                  <p>
                    <strong> Address : </strong> {order.address.address_1}{" "}
                    {order.address.address_2} <br />
                    <strong> Landmark : </strong> {order.address.landmark}{" "}
                    <br />
                    <strong> City : </strong> {order.address.city} <br />
                    <strong> State : </strong>
                    {order.address.state}, {order.address.pin}
                  </p>
                  <p>
                    {" "}
                    <strong> Contact Info </strong>
                    <hr />
                    Name: {order.customer && order.customer.name}
                    <br /> Phone: {order.customer && order.customer.phone}{" "}
                    <br />
                    Email: {order.customer && order.customer.email}
                  </p>
                </div>
              )}

              <div className="address-box">
                <h4>Order Summary </h4>
                <hr></hr>
                <div className="d-flex justify-content-between">
                  <div>
                    <h6>Subtotal</h6>
                  </div>
                  <div>
                    <h5>₹{order.sub_total}</h5>
                  </div>
                </div>
                {order.discount && (
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>Discount</h6>
                    </div>
                    <div>
                      <h5>{order.discount}</h5>
                    </div>
                  </div>
                )}

                {order.delivery_charges && (
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>Delivery Charges</h6>
                    </div>
                    <div>
                      <h5>₹{order.delivery_charges}</h5>
                    </div>
                  </div>
                )}

                {order.tax && (
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>Tax</h6>
                    </div>
                    <div>
                      <h5>{order.tax}</h5>
                    </div>
                  </div>
                )}

                <hr></hr>
                <div className="d-flex justify-content-between total">
                  <div>
                    <h6>Order Total</h6>
                  </div>
                  <div>
                    <h5>₹{order.total_amount}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderCard;
