export const GET_ORDERS_STATED = "GET_ORDERS_STATED";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_ENDED = "GET_ORDERS_ENDED";
export const ADD_ORDER_STATED = "ADD_ORDER_STARTED";
export const ADD_ORDER = "ADD_ORDER";
export const ADD_ORDER_ENDED = "ADD_ORDER_ENDED";
export const EDIT_ORDER_STATED = "EDIT_ORDER_STATED";
export const EDIT_ORDER = "EDIT_ORDER";
export const EDIT_ORDER_ENDED = "EDIT_ORDER_ENDED";
export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_STATED = "GET_ORDER_STATED";
export const GET_ORDER_ENDED = "GET_ORDER_ENDED";
export const RESET_ORDER = "RESET_ORDER";
export const ERROR_ORDER = "ERROR_ORDER";
export const GET_ALL_ORDERS_STATED = "GET_ALL_ORDERS_STATED";
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ALL_ORDERS_ENDED = "GET_ALL_ORDERS_ENDED";
