import React from 'react'
import Breadcrum from '../../components/breadcrum/Breadcrum'
import Footer from '../../components/common/footer/Footer'
import Header from '../../components/common/header/Header'
import { useGetCart } from "../../shared/hooks/UseCart";


function PaymentOption() {
  const [cart, total] = useGetCart();

  return (
    <div>
      <Header />
      <div className="page-content">
        <Breadcrum title={'Payment Method'} />
        <section className="section-padding">
          <div className="container">
            <div className="d-flex align-items-center px-3 py-2 border mb-4">
              <div className="text-start">
                <h4 className="mb-0 h4 fw-bold">Select Payment Method</h4>
              </div>
            </div>
            <div className="row g-4">
              <div className="col-12 col-lg-8 col-xl-8">
                <div className="card rounded-0 payment-method">
                  <div className="row g-0">
                    <div className="col-12 col-lg-4 bg-light">
                      <div className="nav flex-column nav-pills">
                        <button
                          className="nav-link active rounded-0"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-home"
                          type="button"
                        >
                          <i className="bi bi-cash-stack me-2" />
                          Cash On Delivery
                        </button>

                        <button
                          className="nav-link rounded-0 border-bottom-0"
                          id="v-pills-settings-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-settings"
                          type="button"
                        >
                          <i className="bi bi-bank2 me-2" />
                          Online
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-lg-8">
                      <div className="tab-content p-3">
                        <div
                          className="tab-pane fade show active"
                          id="v-pills-home"
                        >
                          <h5 className="mb-3 fw-bold">
                            I would like to pay after product delivery
                          </h5>
                          <button
                            type="button"
                            className="btn btn-ecomm btn-dark w-100 py-3 px-5"
                          >
                            Place Order
                          </button>
                        </div>

                        <div className="tab-pane fade" id="v-pills-settings">
                          <div className="mb-3">
                            <p>Select your Bank</p>
                            <select
                              className="form-select form-select-lg rounded-0"
                              aria-label="Default select example"
                            >
                              <option selected>
                                --Please Select Your Bank--
                              </option>
                              <option value={1}>Bank Name 1</option>
                              <option value={2}>Bank Name 2</option>
                              <option value={3}>Bank Name 3</option>
                            </select>
                          </div>
                          <button
                            type="button"
                            className="btn btn-ecomm btn-dark w-100 py-3 px-5 mb-3"
                          >
                            Pay Now
                          </button>
                          <div className>
                            <p className="mb-0">
                              Note: After clicking on the button, you will be
                              directed to a secure gateway for payment. After
                              completing the payment process, you will be
                              redirected back to the website to view details of
                              your order.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*end row*/}
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-4">
                <div className="card rounded-0 mb-3">
                  <div className="card-body">
                    <h5 className="fw-bold mb-4">Order Summary</h5>
                    <div className="hstack align-items-center justify-content-between">
                      <p className="mb-0">Bag Total</p>
                      <p className="mb-0">₹{total}</p>
                    </div>
                    <hr />
                    <div className="hstack align-items-center justify-content-between">
                      <p className="mb-0">Bag discount</p>
                      <p className="mb-0 text-success">- ₹178.00</p>
                    </div>
                    <hr />
                    <div className="hstack align-items-center justify-content-between">
                      <p className="mb-0">Delivery</p>
                      <p className="mb-0">₹0.00</p>
                    </div>
                    <hr />
                    <div className="hstack align-items-center justify-content-between fw-bold text-content">
                      <p className="mb-0">Total Amount</p>
                      <p className="mb-0">₹{total}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end row*/}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}

export default PaymentOption
