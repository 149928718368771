import {
  GET_WISHLIST,
  WISHLIST_ERROR,
  TOGGLE_WISHLIST,
} from "../types/wishlist_type";

const initialState = {
  wishlist: localStorage.getItem(process.env.REACT_APP_WISHLIST)
    ? JSON.parse(localStorage.getItem(process.env.REACT_APP_WISHLIST))
    : null,
  loading: true,
  error: {},
  is_open: false,
};

export const wishlist_reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_WISHLIST:
      return {
        ...state,
        wishlist: payload,
        loading: false,
        error: {},
      };
    case TOGGLE_WISHLIST:
      return {
        ...state,
        is_open: payload,
      };

    case WISHLIST_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
