export const GET_TESTIMONIALS_STATED = "GET_TESTIMONIALS_STATED";
export const GET_TESTIMONIALS = "GET_TESTIMONIALS";
export const GET_TESTIMONIALS_ENDED = "GET_TESTIMONIALS_ENDED";
export const ADD_TESTIMONIAL_STATED = "ADD_TESTIMONIAL_STARTED";
export const ADD_TESTIMONIAL = "ADD_TESTIMONIAL";
export const ADD_TESTIMONIAL_ENDED = "ADD_TESTIMONIAL_ENDED";
export const EDIT_TESTIMONIAL_STATED = "EDIT_TESTIMONIAL_STATED";
export const EDIT_TESTIMONIAL = "EDIT_TESTIMONIAL";
export const EDIT_TESTIMONIAL_ENDED = "EDIT_TESTIMONIAL_ENDED";
export const GET_TESTIMONIAL = "GET_TESTIMONIAL";
export const GET_TESTIMONIAL_STATED = "GET_TESTIMONIAL_STATED";
export const GET_TESTIMONIAL_ENDED = "GET_TESTIMONIAL_ENDED";
export const RESET_TESTIMONIAL = "RESET_TESTIMONIAL";
export const ERROR_TESTIMONIAL = "ERROR_TESTIMONIAL";
export const GET_ALL_TESTIMONIALS_STATED = "GET_ALL_TESTIMONIALS_STATED";
export const GET_ALL_TESTIMONIALS = "GET_ALL_TESTIMONIALS";
export const GET_ALL_TESTIMONIALS_ENDED = "GET_ALL_TESTIMONIALS_ENDED";
