import React from "react";
import {
  useAddToCart,
  useCheckInCart,
  useDecreaseQuantity,
  useRemoveFromCart,
} from "../../shared/hooks/UseCart";
import { Link, useHistory } from "react-router-dom";

function CartComponent({ product, activeVariantVersion }) {
  const history = useHistory();

  const [productInCart] = useCheckInCart(product, activeVariantVersion);
  const [cart, addData] = useAddToCart();
  const [removeFromCart] = useRemoveFromCart();
  const [decreaseQuantity] = useDecreaseQuantity();

  const addToCart = async (product) => {
    if (!product.is_variable_product) {
      await addData({
        name: product.name,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        slug: product.slug,
        image: product.media && product.media[0],
        product: product._id,
      });
    } else {
      let title = "";
      activeVariantVersion &&
        activeVariantVersion.options &&
        activeVariantVersion.options.map((item) => {
          title = title + " " + item.value;
        });
      await addData({
        name: `${product.name} ${title}`,
        regular_price: product.regular_price,
        sale_price: product.sale_price,
        slug: `${product.slug}?variation=${activeVariantVersion._id}`,
        image: product.media && product.media[0],
        product: product._id,
      });
    }
  };

  const buyNow = async (product) => {
    await addData({
      name: product.name,
      regular_price: product.regular_price,
      sale_price: product.sale_price,
      slug: product.slug,
      image: product.media && product.media[0],
      product: product._id,
    });
    history.push("/checkout");
  };
  return (
    <div className="buy-now-flex">
      {productInCart ? (
        <div className="d-flex">
          <div className="wd50 m-2">
            <div className="d-flex align-items-center">
              <div>
                <button
                  className="btn btn-increase-quantity"
                  onClick={() =>
                    decreaseQuantity(
                      activeVariantVersion
                        ? `${product.slug}?variation=${activeVariantVersion._id}`
                        : product.slug
                    )
                  }
                >
                  {" "}
                  <i className="fa fa-minus"></i>{" "}
                </button>{" "}
              </div>
              <div>
                <div className="quantity">{productInCart.quantity}</div>
              </div>
              <div>
                <button
                  className="btn btn-increase-quantity"
                  onClick={() => addToCart(product)}
                >
                  {" "}
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="wd50">
            <Link
              className="btn btn-lg btn-dark btn-ecomm px-5 py-3 "
              // onClick={() => addToCart(product)}
              to={"/cart"}
            >
              <i className="fa fa-check-circle"></i> View Cart
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex  ">
            <div className="wd50">
              <button
                className="btn btn-lg btn-outline-dark btn-ecomm px-5 py-3"
                onClick={() => buyNow(product)}
              >
                <i className="bi bi-suit-heart me-2" />
                Buy Now
              </button>
            </div>
            <div className="wd50">
              <button
                className="btn btn-lg btn-dark btn-ecomm px-5 py-3 "
                onClick={() => addToCart(product)}
              >
                <i className="bi bi-basket2 me-2" />
                Add To Cart
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CartComponent;
