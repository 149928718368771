import React from "react";
import { URI } from "../../domain/constant";
import Image from "../common/Image";

function SingleProductImage({ image }) {
  return (
    <div className="col">
      <div
        className="img-thumb-container overflow-hidden position-relative"
        data-fancybox="gallery"
        data-src="//assets/images/product-images/01.jpg"
      >
        {/* <img src={`${URI}${image}`} className="single-product-image" /> */}
        <Image src={`${URI}${image}`} className="single-product-image" />
      </div>
    </div>
  );
}

export default SingleProductImage;
