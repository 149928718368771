import {
  GET_WISHLIST,
  WISHLIST_ERROR,
  TOGGLE_WISHLIST,
} from "../types/wishlist_type";

// Get posts
export const getWishlist = () => async (dispatch) => {
  try {
    const wishlist = localStorage.getItem(process.env.REACT_APP_WISHLIST);
    if (wishlist) {
      dispatch({
        type: GET_WISHLIST,
        payload: JSON.parse(wishlist),
      });
    } else {
      dispatch({
        type: GET_WISHLIST,
        payload: [],
      });
    }
  } catch (err) {
    dispatch({
      type: WISHLIST_ERROR,
      payload: err,
    });
  }
};

export const addToWishlist = (product) => async (dispatch) => {
  try {
    const newCart = localStorage.getItem(process.env.REACT_APP_WISHLIST);
    const cart = JSON.parse(newCart);

    if (cart) {
      const filterProduct = cart.filter((item) => item._id === product._id);

      if (filterProduct.length > 0) {
        const newProducts = cart.filter((item) => item._id !== product._id);
        localStorage.setItem(
          process.env.REACT_APP_WISHLIST,
          JSON.stringify(newProducts)
        );
      } else {
        localStorage.setItem(
          process.env.REACT_APP_WISHLIST,
          JSON.stringify([...cart, product])
        );
      }
    } else {
      localStorage.setItem(
        process.env.REACT_APP_WISHLIST,
        JSON.stringify([product])
      );
    }
    dispatch(getWishlist());
  } catch (err) {
    dispatch({
      type: WISHLIST_ERROR,
      payload: err,
    });
  }
};

export const toggleWishlist = (value) => async (dispatch) => {
  dispatch({
    type: TOGGLE_WISHLIST,
    payload: value,
  });
};
