import React from 'react';
import Breadcrum from '../../components/breadcrum/Breadcrum';
import Footer from '../../components/common/footer/Footer';
import Header from '../../components/common/header/Header';
import {
  useGetCart,
  useRemoveFromCart,
  useDecreaseQuantity,
  useCheckInCart,
  useAddToCart,
} from '../../shared/hooks/UseCart';
import { Link } from 'react-router-dom';
import { URI } from '../../domain/constant';
import DiscountSection from '../../components/product/DiscountSection';
import {
  useAddToWishlist,
  useCheckInWishlist,
} from '../../shared/hooks/UseWishlist';
import {
  useSingleProduct,
  useSingleProductBySlug,
} from '../../shared/hooks/UseProduct';
// import { use } from "../../shared/hooks/UseCoupon"

function Cart({ match }) {
  const [cart, total] = useGetCart();
  console.log('CART-', cart);
  // const [productInCart] = useCheckInCart(product);
  const [removeFromCart] = useRemoveFromCart();
  const [cartData, addToCart] = useAddToCart();
  const [decreaseQuantity] = useDecreaseQuantity();
  console.log('CART TO CHECK', cart, cart.length);

  return (
    <div>
      <Header />
      <div className='page-content'>
        <Breadcrum title={'Cart'} />
        <section className='section-padding'>
          <div className='container'>
            <div className='d-flex align-items-center px-3 py-2 border mb-4'>
              <div className='text-start'>
                <h4 className='mb-0 h4 fw-bold'>
                  My Bag ({cart && cart.length} items)
                </h4>
              </div>
              <div className='ms-auto'>
                <Link to={`/shop`}>
                  <button type='button' className='btn btn-light btn-ecomm'>
                    Continue Shopping
                  </button>
                </Link>
              </div>
            </div>
            <div className='row g-4'>
              <div className='col-12 col-xl-8'>
                {cart &&
                  cart.map((item) => {
                    return (
                      <div className='card rounded-0 mb-3'>
                        <div className='card-body'>
                          <div className='d-flex flex-column flex-lg-row gap-3'>
                            <div className='product-img'>
                              <img
                                src={`${URI}${item.image}`}
                                width={150}
                                alt
                              />
                            </div>
                            <div className='product-info flex-grow-1'>
                              <h5 className='fw-bold mb-0'>{item.name}</h5>
                              <div className='product-price d-flex align-items-center gap-2 mt-3'>
                                <div className='h6 fw-bold'>
                                  ₹{item.sale_price}
                                </div>
                                <div className='h6 fw-light text-muted text-decoration-line-through'>
                                  ₹{item.regular_price}
                                </div>
                                <div className='h6 fw-bold text-danger'>
                                  <DiscountSection />
                                </div>
                              </div>
                              <div className='mt-3 hstack gap-2'>
                                <button
                                  type='button'
                                  className='btn btn-sm btn-light border rounded-0'
                                  data-bs-toggle='modal'
                                  data-bs-target='#SizeModal'
                                >
                                  Size : M
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-sm btn-light border rounded-0'
                                  data-bs-toggle='modal'
                                  data-bs-target='#QtyModal'
                                >
                                  Qty : {item.quantity}
                                </button>
                              </div>
                            </div>
                            <div className='d-none d-lg-block vr' />
                            <div className='d-grid gap-2 align-self-start align-self-lg-center'>
                              <button
                                onClick={() => removeFromCart(item.slug)}
                                type='button'
                                className='btn btn-ecomm'
                              >
                                <i className='bi bi-x-lg me-2' />
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className='col-12 col-xl-4'>
                <div className='card rounded-0 mb-4'>
                  <div className='card-body'>
                    <h5 className='fw-bold mb-4'>Apply Coupon</h5>
                    <div className='input-group'>
                      <input
                        type='text'
                        className='form-control rounded-0'
                        placeholder='Enter coupon code'
                      />
                      <button
                        className='btn btn-dark btn-ecomm rounded-0'
                        type='button'
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
                <div className='card rounded-0 mb-3'>
                  <div className='card-body'>
                    <h5 className='fw-bold mb-4'>Order Summary</h5>
                    <div className='hstack align-items-center justify-content-between'>
                      <p className='mb-0'>Bag Total</p>
                      <p className='mb-0'>₹{total}</p>
                    </div>

                    <hr />
                    <div className='hstack align-items-center justify-content-between'>
                      <p className='mb-0'>Delivery</p>
                      <p className='mb-0'>₹0.00</p>
                    </div>
                    <hr />
                    <div className='hstack align-items-center justify-content-between fw-bold text-content'>
                      <p className='mb-0'>Total Amount</p>
                      <p className='mb-0'>₹{total}</p>
                    </div>
                    <div className='d-grid mt-4'>
                      <Link to='/checkout'>
                        <button
                          type='button'
                          className='btn btn-dark btn-ecomm py-3 px-5'
                        >
                          Place Order
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end row*/}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Cart;
