import React from "react";

function ProductTitle({ product, activeVariantVersion }) {
  if (product && product.is_variable_product && activeVariantVersion) {
    return (
      <>
        {product.name}
        {activeVariantVersion &&
          activeVariantVersion.options &&
          activeVariantVersion.options.map((item) => {
            return <> {item.value} </>;
          })}
      </>
    );
  }
  return <>{product.name}</>;
}

export default ProductTitle;
