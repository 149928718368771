export const GET_SITEPAGES_STATED = "GET_SITEPAGES_STATED";
export const GET_SITEPAGES = "GET_SITEPAGES";
export const GET_SITEPAGES_ENDED = "GET_SITEPAGES_ENDED";
export const ADD_SITEPAGE_STATED = "ADD_SITEPAGE_STARTED";
export const ADD_SITEPAGE = "ADD_SITEPAGE";
export const ADD_SITEPAGE_ENDED = "ADD_SITEPAGE_ENDED";
export const EDIT_SITEPAGE_STATED = "EDIT_SITEPAGE_STATED";
export const EDIT_SITEPAGE = "EDIT_SITEPAGE";
export const EDIT_SITEPAGE_ENDED = "EDIT_SITEPAGE_ENDED";
export const GET_SITEPAGE = "GET_SITEPAGE";
export const GET_SITEPAGE_STATED = "GET_SITEPAGE_STATED";
export const GET_SITEPAGE_ENDED = "GET_SITEPAGE_ENDED";
export const RESET_SITEPAGE = "RESET_SITEPAGE";
export const ERROR_SITEPAGE = "ERROR_SITEPAGE";
export const GET_ALL_SITEPAGES_STATED = "GET_ALL_SITEPAGES_STATED";
export const GET_ALL_SITEPAGES = "GET_ALL_SITEPAGES";
export const GET_ALL_SITEPAGES_ENDED = "GET_ALL_SITEPAGES_ENDED";
