export const GET_VENDORS_STATED = "GET_VENDORS_STATED";
export const GET_VENDORS = "GET_VENDORS";
export const GET_VENDORS_ENDED = "GET_VENDORS_ENDED";
export const ADD_VENDOR_STATED = "ADD_VENDOR_STARTED";
export const ADD_VENDOR = "ADD_VENDOR";
export const ADD_VENDOR_ENDED = "ADD_VENDOR_ENDED";
export const EDIT_VENDOR_STATED = "EDIT_VENDOR_STATED";
export const EDIT_VENDOR = "EDIT_VENDOR";
export const EDIT_VENDOR_ENDED = "EDIT_VENDOR_ENDED";
export const GET_VENDOR = "GET_VENDOR";
export const GET_VENDOR_STATED = "GET_VENDOR_STATED";
export const GET_VENDOR_ENDED = "GET_VENDOR_ENDED";
export const RESET_VENDOR = "RESET_VENDOR";
export const ERROR_VENDOR = "ERROR_VENDOR";
export const GET_ALL_VENDORS_STATED = "GET_ALL_VENDORS_STATED";
export const GET_ALL_VENDORS = "GET_ALL_VENDORS";
export const GET_ALL_VENDORS_ENDED = "GET_ALL_VENDORS_ENDED";
