import React from "react";

function DiscountSection({ product, activeVariantVersion, cssName }) {
  if (product && product.is_variable_product && activeVariantVersion) {
    return (
      <>
        {product.regular_price > activeVariantVersion.price && (
          <div class={cssName ? cssName : "ribban"}>
            {parseInt(
              ((product.regular_price - activeVariantVersion.price) /
                product.regular_price) *
                100
            )}
            % off
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {product && product.regular_price > product.sale_price && (
        <div class={cssName ? cssName : "ribban"}>
          {parseInt(
            ((product.regular_price - product.sale_price) /
              product.regular_price) *
              100
          )}
          % off
        </div>
      )}
    </>
  );
}

export default DiscountSection;
