export const GET_CUSTOMERS_STATED = "GET_CUSTOMERS_STATED";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_ENDED = "GET_CUSTOMERS_ENDED";
export const ADD_CUSTOMER_STATED = "ADD_CUSTOMER_STARTED";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const ADD_CUSTOMER_ENDED = "ADD_CUSTOMER_ENDED";
export const EDIT_CUSTOMER_STATED = "EDIT_CUSTOMER_STATED";
export const EDIT_CUSTOMER = "EDIT_CUSTOMER";
export const EDIT_CUSTOMER_ENDED = "EDIT_CUSTOMER_ENDED";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_STATED = "GET_CUSTOMER_STATED";
export const GET_CUSTOMER_ENDED = "GET_CUSTOMER_ENDED";
export const RESET_CUSTOMER = "RESET_CUSTOMER";
export const ERROR_CUSTOMER = "ERROR_CUSTOMER";
export const GET_ALL_CUSTOMERS_STATED = "GET_ALL_CUSTOMERS_STATED";
export const GET_ALL_CUSTOMERS = "GET_ALL_CUSTOMERS";
export const GET_ALL_CUSTOMERS_ENDED = "GET_ALL_CUSTOMERS_ENDED";
