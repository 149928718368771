import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getWishlist,
  addToWishlist,
} from "../../store/actions/wishlist_action";

export const useGetWishlist = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.wishlist);
  const { wishlist } = data;

  useEffect(() => {
    dispatch(getWishlist());
  }, []);
  return [wishlist];
};

export const useAddToWishlist = () => {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.wishlist);
  const { wishlist } = data;
  const addData = async (data) => {
    await dispatch(addToWishlist(data));
  };

  return [wishlist, addData];
};

export const useCheckInWishlist = (product) => {
  const [productInWishlist, setProductInWishlist] = useState(false);
  const data = useSelector((state) => state.wishlist);
  const { wishlist } = data;
  useEffect(() => {
    if (wishlist && product) {
      const filterData = wishlist.filter((item) => item._id == product._id);
      if (filterData && filterData.length > 0) {
        setProductInWishlist(true);
      } else {
        setProductInWishlist(false);
      }
    }
  }, [wishlist]);

  return [productInWishlist];
};
